<!-- 视图 -->
<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('New Quality Management') }}</div>
            </div>
            <template>
                <div>
                    <el-form :model="formObj" ref="refForm" :rules="formObjRules" label-position="top">
                        <!--表头名称-->
                        <el-divider content-position="left">{{ $t('Quality Manage') }}</el-divider>
                        <el-row class="el-row_style">
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('standard number')" prop="code" label-width="120px">
                                    <el-input v-model="formObj.code" filterable style="width: calc(100% - 115px);"
                                              clearable placeholder=""></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :sm="24" :md="12" class="el-col_style">
                                <el-form-item :label="$t('standard name')" prop="name" label-width="120px">
                                    <el-input v-model="formObj.name" filterable style="width: calc(100% - 115px);"
                                              clearable placeholder=""></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!--表体名称-->
                        <div class="smallTit">{{ $t('Parameter Standard') }}</div>
                        <el-table :data="formObj.itemTable" :row-class-name="tableRowClassName" height="420"
                                  style="width: 100%" :header-cell-class-name="starAdd">
                            <el-table-column :label="$t('Index')" type="index" width="50px" align='center'>
                            </el-table-column>
                            <el-table-column :label="$t('parameter number')" align='center'>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.parameterCode'">
                                        <el-input v-model="scope.row.parameterCode" clearable :disabled="true">
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('parameter name')" align='center'>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.parameterName'">
                                        <el-input v-model="scope.row.parameterName" clearable :disabled="true">
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('parameter type')" align='center' v-if="false">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.type'">
                                        <el-input v-model="scope.row.type" clearable>
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('standard description')" align='center'>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.specificStandards'">
                                        <el-input v-model="scope.row.specificStandards"
                                                  :disabled="scope.row.type!==0" clearable>
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <!--              <el-table-column :label="$t('枚举类型')"  align='center'>-->
                            <!--                <template slot-scope="scope">-->
                            <!--                  <el-form-item :prop="'tableData.' + scope.$index + '.enumerationParentKey'">-->
                            <!--                    <el-select  v-model="scope.row.enumerationParentKey" clearable :disabled="scope.row.type==1?false:true">-->
                            <!--                    </el-select>-->
                            <!--                  </el-form-item>-->
                            <!--                </template>-->
                            <!--              </el-table-column>-->
                            <!--              <el-table-column :label="$t('枚举标准')"  align='center'>-->
                            <!--                <template slot-scope="scope">-->
                            <!--                  <el-form-item :prop="'tableData.' + scope.$index + '.enumerationItemKey'">-->
                            <!--                    <el-select  v-model="scope.row.enumerationItemKey" clearable :disabled="scope.row.type==1?false:true">-->
                            <!--                    </el-select>-->
                            <!--                  </el-form-item>-->
                            <!--                </template>-->
                            <!--              </el-table-column>-->
                            <el-table-column :label="$t('MAXIMUM')" align='center'>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.maxValue'" :rules="formObjRules.maxValue">
                                        <el-input-number v-model="scope.row.maxValue" clearable :min="0"
                                                         :disabled="scope.row.type!==2">
                                        </el-input-number>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('MINIMUM')" align='center'>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.minValue'">
                                        <el-input-number v-model="scope.row.minValue" clearable :min="0"
                                                         :disabled="scope.row.type!==2"></el-input-number>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align:right;padding:50px 0;">
                            <el-button type="primary" @click="submitForm" v-if="this.routeSign!=='update'">{{$t('Save')}}</el-button>
                            <el-button @click="resetForm">{{ $t('Cancel') }}</el-button>
                        </div>
                    </el-form>
                </div>
            </template>
        </basic-container>
    </div>
</template>
<!-- 逻辑js -->
<script>
    //引入对象
    import {list} from "@/api/parameter";
    import {add, parameterList} from "@/api/parameterStandard";

    export default {
        feedbackData: [],
        //data：返回实体对象
        data() {
            var VMaxValue = (rule, value, callback) => {
                console.log("222224",rule,value);
                let indexRule = rule.fullField.split(".")
                var currRow=this.formObj.itemTable[parseInt(indexRule[1])];
                console.log("22222244",currRow.minValue ,currRow.type,this.formObj.itemTable[parseInt(indexRule[1])]);
                if (currRow.type === 2){
                    if (currRow.minValue === 0 && currRow.maxValue === 0){
                        callback();
                    }
                    if (currRow.minValue > currRow.maxValue){
                        callback(new Error('最小值不能大于最大值'));
                    }else if (currRow.minValue === currRow.maxValue){
                        callback(new Error('最小值不能等于最大值'));
                    }
                    callback();
                }
                callback();
            };
            return {
                //路由传递信息
                routeSign: '', routeId: '',
                //表单信息
                formObj: {
                    //表头信息
                    code: '', name: '',
                    //表体信息
                    itemTable: [],
                },
                formObjRules: {
                    code: [{required: true, message: '请输入标准名称', trigger: 'blur'}],
                    name: [{required: true, message: '请输入标准编号', trigger: 'blur'}],
                    maxValue: [{ type: "number", validator: VMaxValue, trigger: 'blur' }],
                },
            };
        },
        computed: {
            setData() {
                return {}
            }
        },
        created() {
            this.routeSign = this.$route.query.sign;
            this.routeId = this.$route.query.ID;
            if (this.routeSign === 'update') {
                this.xiangqing();
            }else {
                const xx = {
                    pageNo: 1,
                    pageSize: 100,
                    total: 100,
                };
                list(xx).then(res => {
                    res.data.data.items.forEach(v => {
                        let item = {
                            Id: "",
                            code: "",
                            name: "",
                            parameterCode: v.code,
                            parameterName: v.name,
                            type: v.type,
                            specificStandards: "",
                            minValue: "",
                            maxValue: "",
                            enumerationItemKey: "",
                            enumerationParentKey: ""
                        };
                        this.formObj.itemTable.push(item);
                    })
                });
                this.init()
            }
        },
        watch: {
            $route() {
                this.init()
            }
        },
        methods: {
            //初始加载数据
            init() {
                this.routeSign = this.$route.query.sign;
                this.routeId = this.$route.query.ID;
                if (this.routeSign === 'edit') {
                    this.isEditLoad = true;
                    this.getLoadForm();
                }

            },
            addrow() {
                let item = {
                    Id: "",
                    code: "",
                    name: "",
                    parameterCode: "",
                    specificStandards: "",
                    minValue: "",
                    maxValue: "",
                    enumerationItemKey: "",
                    enumerationParentKey: ""
                }
                this.formObj.itemTable.push(item);
            },
            xiangqing(){
                this.routeSign = this.$route.query.sign;
                this.routeCode = this.$route.query.standardCode;
                const xx = {
                    pageNo: 1,
                    pageSize: 100,
                    total: 100,
                    standardCode: this.routeCode
                };
                console.log('xx', xx);
                parameterList(xx).then(res => {
                    console.log(res.data.data)
                    const info = res.data.data;
                    this.formObj.code = info[0].standardCode;
                    this.formObj.name = info[0].standardCode;
                    this.formObj.itemTable = res.data.data.map((v) => {
                        return {
                            ...v,
                            parameterCode: v.code,
                            parameterName: v.name
                        }
                    });
                    console.log(this.data)
                })
            },
            //提交数据
            submitForm() {
                this.$nextTick(() => {//避免点击两次
                    let formName = "refForm";
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            //新增
                            console.log("this.formObj", this.formObj)
                            var deetailList = []
                            this.formObj.itemTable.forEach(v => {
                                let item = {
                                    "enumerationItemKey": v.enumerationItemKey,
                                    "enumerationParentKey": v.enumerationParentKey,
                                    "maxValue": v.maxValue,
                                    "minValue": v.minValue,
                                    "parameterCode": v.parameterCode,
                                    "specificStandards": v.specificStandards,
                                    "standardCode": v.code,
                                };
                                this.formObj.itemTable.push(item);
                                debugger
                                deetailList.push(item);
                            })
                            var info = {
                                code: this.formObj.code,
                                name: this.formObj.name,
                                parameterStandardDtos: deetailList,
                            }
                            add(info).then((res) => {
                                this.request.post(res)
                                this.$message({
                                    type: "success",
                                    message: res.data.msg,
                                });
                                this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value === this.$route.fullPath), 1);
                                this.$router.push({path: '/quality/parameterStandard'});
                            })
                        } else {
                            return false;
                        }
                    });
                });
            },
            //取消按钮
            resetForm() {
                this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value === this.$route.fullPath), 1);
                this.$router.push({path: '/quality/parameterStandard'});
            },
            //添加行号
            tableRowClassName({row, rowIndex}) {
                // 把每一行的索引放进row
                row.index = rowIndex
            },
            //列头加星号
            starAdd(obj) {
                if (obj.columnIndex === 2 || obj.columnIndex === 3 || obj.columnIndex === 7 || obj.columnIndex === 8 || obj.columnIndex === 9 || obj.columnIndex === 11) {
                    return 'star';
                }
            },
        }
    }
</script>